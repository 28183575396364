const links = [
    {
        label: 'Home',
        url: '/'
    },
    {
        label: 'Sobre mim',
        url: '/sobre'
    },
    {
        label: 'Blog',
        url: '/blog'
    },
]

export default links;